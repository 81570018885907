import React, { useEffect, useRef } from 'react'
import pricesSpecialClasses from './PricesSpecial.module.scss'

const PricesSpecial = ({ title, text, subText, setPurchaseModalHeight = () => {} }) => {
    const ref = useRef(null)
    
    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setPurchaseModalHeight(ref.current.clientHeight)
    })
    
    return (
        <div className={pricesSpecialClasses.wrapper} ref={ref}>
            <h3>{title}</h3>
            <br />
            <div className={pricesSpecialClasses.text}>{text}</div>
            <br />
            <div className={pricesSpecialClasses.subtext}>{subText}</div>
            <br />
        </div>
    )
}

export default PricesSpecial