import React from 'react'
import isEmpty from 'lodash/isEmpty'
import { getTypeId } from 'training-lane-booking-shared/utilities/functions'
import purchasePickedTitleClasses from './PurchasePickedTitle.module.scss'

const PurchasePickedTitle = ({ itemType }) =>
    !isEmpty(itemType) ? (
        <span style={{ color: itemType.color }}>
            <div className={purchasePickedTitleClasses.title}>
                {itemType.id === getTypeId('tlc')
                    ? itemType.description.short
                    : itemType.description.categoryAlt ?? itemType.description.category}
            </div>
                {
                    itemType.description.people &&
                    (
                        <div className={purchasePickedTitleClasses.title}>
                            {itemType.description.people}
                        </div>
                    )
                }
        </span>
    ) : null

export default PurchasePickedTitle