import React from 'react'
import textConstants from 'training-lane-booking-shared/utilities/text'

const Welcome = ({ firstName = null }) => (
    firstName ? (
        <>
            <div>{textConstants.WELCOME_BACK},</div>
            <div>{firstName}!</div>
        </>
    ) : <div>{textConstants.WELCOME}!</div>
)

export default Welcome