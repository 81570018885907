import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import has from 'lodash/has'
import isEqual from 'lodash/isEqual'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import PurchasePickedTitle from '../Purchase/PurchasePickedTitle/PurchasePickedTitle'
import PurchasePickedInfoShared from 'training-lane-booking-shared/components/Purchase/PurchasePickedInfoShared/PurchasePickedInfoShared'
import PurchasePickedInfo from '../Purchase/PurchasePickedInfo/PurchasePickedInfo'
import Counter from '../Counter/Counter'
import CreditCounter from '../CreditCounter/CreditCounter'
import Discount from '../Purchase/Discount/Discount'
import Strikethrough from '../UI/Strikethrough/Strikethrough'
import {
    filterTypesByCapabilities,
    getBundleCount,
    getGrandTotal,
    getItemPrice,
    getItemPriceWithType,
    getCapabilitiesByRoleId
} from 'training-lane-booking-shared/utilities/functions'
import { roles, types } from 'training-lane-booking-shared/utilities/constants'
import textConstants from 'training-lane-booking-shared/utilities/text'
import unpaidModalClasses from '../Modals/UnpaidModal/UnpaidModal.module.scss'
import unpaidListClasses from './UnpaidList.module.scss'
import GroupSessionsPriceList from '../GroupSessionsPriceList/GroupSessionsPriceList'

const UnpaidList = ({
    unpaidAdmin = false,
    user,
    roleId,
    prices,
    credits,
    nonRefundableCredits,
    promoCredits,
    groupedCredits = {},
    groupSessionsUnpaidTotal = 0,
    allowCheckout = false,
    tempCredits,
    tempTotal,
    setTempCredits = () => {},
    setTempTotal = () => {},
    logPayment = () => {},
    simpleTally = false,
    period = null,
    handleUpdateCounter = () => {}
}) => {
    const [grandTotal, setGrandTotal] = useState(0)
    const [discounts, setDiscounts] = useState({})
    const [tempTotalFinal, setTempTotalFinal] = useState(0)
    const [tempCreditsActive, setTempCreditsActive] = useState(false)

    // noinspection JSUnresolvedVariable
    const isAdmin = useSelector(
        state => roles.admin.id === state.firebase.profile.roleId
    )

    // noinspection JSUnresolvedVariable
    const isStaff = useSelector(
        state => roles.staff.id === state.firebase.profile.roleId
    )

    const tallyIncludeGroupSessions = useSelector(state => state.slotPicker.tallyIncludeGroupSessions)

    useEffect(() => {
        if (unpaidAdmin) {
            let localDiscounts = {}
            let localGrandTotal = 0
            if (
                !isEmpty(groupedCredits.dailyLanes) ||
                !isEmpty(groupedCredits.weeklyIrs)
            ) {
                if (!isEmpty(groupedCredits.dailyLanes)) {
                    localDiscounts.dailyLanes = Object.keys(groupedCredits.dailyLanes).length
                    localDiscounts.dailyLanesTotal =
                        localDiscounts.dailyLanes * getItemPrice(prices.l12['1'], 1)
                    localGrandTotal -= localDiscounts.dailyLanesTotal
                }
                if (!isEmpty(groupedCredits.weeklyIrs)) {
                    let weeklyCount = 0
                    Object.keys(groupedCredits.weeklyIrs).forEach(year => {
                        weeklyCount += Object.keys(groupedCredits.weeklyIrs[year]).length
                    })
                    localDiscounts.weeklyIrs = weeklyCount
                    localDiscounts.weeklyIrsTotal =
                        localDiscounts.weeklyIrs * getItemPrice(prices.ir1['1'], 1)
                    localGrandTotal -= localDiscounts.weeklyIrsTotal
                }
            }
            setDiscounts(localDiscounts)

            setTempTotal(
                (simpleTally ? 0 : parseFloat(getGrandTotal(tempCredits, prices)) + localGrandTotal) +
                (
                    tallyIncludeGroupSessions
                        ? parseFloat(getItemPrice(groupSessionsUnpaidTotal))
                        : 0
                )
            )

            setGrandTotal(
                (simpleTally ? 0 : parseFloat(getGrandTotal(credits, prices)) + localGrandTotal) +
                (
                    tallyIncludeGroupSessions
                        ? parseFloat(getItemPrice(groupSessionsUnpaidTotal))
                        : 0
                )
            )
        }
    }, [
        credits,
        tempCredits,
        groupSessionsUnpaidTotal,
        groupedCredits.dailyLanes,
        groupedCredits.weeklyIrs,
        prices,
        setTempTotal,
        simpleTally,
        tallyIncludeGroupSessions,
        unpaidAdmin
    ])

    useEffect(() => {
        setTempCreditsActive(
            !isEqual(sortBy(credits), sortBy(tempCredits)) &&
            !isStaff &&
            period === null
        )
    }, [credits, isStaff, period, simpleTally, tempCredits])

    useEffect(() => {
        let innerTotal = parseFloat(tempTotal)
        if (!isEmpty(discounts)) {
            if (discounts.dailyLanesTotal) {
                innerTotal -= discounts.dailyLanesTotal
            }
            if (discounts.weeklyIrsTotal) {
                innerTotal -= discounts.weeklyIrsTotal
            }
        }
        if (innerTotal < 0) {
            innerTotal = 0
        }
        
        setTempTotalFinal(innerTotal)
    }, [discounts, tempTotal])

    const filteredTypes = filterTypesByCapabilities(
        types,
        getCapabilitiesByRoleId(user?.roleId ?? roleId),
        true
    )

    const showCreditsCounter = isAdmin && unpaidAdmin && period === null

    const additionalInfo = currentTypeId => {
        const addlInfo = []
        if (
            nonRefundableCredits !== undefined &&
            nonRefundableCredits[currentTypeId] > 0
        ) {
            addlInfo.push(
                `${textConstants.NON_REFUNDABLE}: ${nonRefundableCredits[currentTypeId]}`
            )
        }
        if (
          promoCredits !== undefined &&
          promoCredits[currentTypeId] !== undefined &&
          promoCredits[currentTypeId] !== 0
        ) {
          addlInfo.push(
            `${textConstants.CREDITED}: ${promoCredits[currentTypeId]}`
          )
        }
        return addlInfo
    }

    return (
        <>
            {filteredTypes.reduce((creditsCollector, currentType, i) => {
                const currentTypeId = currentType.id
                const bundlesOfType = prices[currentTypeId]
                const currentBundleCount = getBundleCount(
                    bundlesOfType,
                    credits[currentTypeId],
                    [roles.member.id, roles.trainer.id].includes(roleId)
                )
                const creditCounterProps = {
                    user,
                    credits,
                    type: currentType,
                    prices,
                    groupSessionsUnpaidTotal,
                    tempCreditsActive,
                    tempCredits,
                    tempTotal,
                    setTempCredits,
                    setTempTotal,
                    logPayment,
                    simpleTally,
                    handleUpdateCounter
                }
                creditsCollector.push(
                    <Fragment key={i}>
                        <div
                            className={classNames(
                                unpaidModalClasses.fullWidthGrid,
                                'grid-wrapper'
                            )}>
                            <div className={`col-${showCreditsCounter ? 6 : 10} align-left`}>
                                <PurchasePickedTitle itemType={currentType} />
                            </div>
                            {showCreditsCounter && (
                                <div className="col-5 align-right">
                                    <div className={unpaidListClasses.creditCounterContainer}>
                                        <CreditCounter {...creditCounterProps} />
                                    </div>
                                </div>
                            )}
                            <div className={`col-${showCreditsCounter ? 1 : 2} align-right`}>
                                <Counter
                                    credits={
                                        tempCredits && tempCreditsActive ? tempCredits : credits
                                    }
                                    type={currentType}
                                    tempCreditActive={
                                        tempCredits &&
                                        credits[currentType.id] !== tempCredits[currentType.id] &&
                                        period === null
                                    }
                                    isStaff={isStaff}
                                />
                            </div>
                        </div>
                        {!simpleTally && (
                            <div
                                className={classNames(
                                    'grid-wrapper',
                                    unpaidModalClasses.fullWidthGrid
                                )}>
                                <div
                                    className={classNames(
                                        'col-12',
                                        'align-right',
                                        unpaidModalClasses.totalWrapper
                                    )}>
                                    {currentBundleCount.reduce((bundleCollector, bundle, j) => {                                        
                                        for (const [stringKey, count] of Object.entries(bundle)) {
                                            const key = parseInt(stringKey, 10)
                                            if (has(bundlesOfType, key)) {
                                                const itemPrice = getItemPriceWithType(
                                                    bundlesOfType[key],
                                                    key,
                                                    currentType.id
                                                )
                                                const subTotal = count * itemPrice
                                                bundleCollector.push(
                                                    <div key={j}>
                                                        <span>{`${count} x`}&nbsp;</span>
                                                        <PurchasePickedInfoShared
                                                            PurchasePickedInfo={PurchasePickedInfo}
                                                            Strikethrough={Strikethrough}
                                                            bundle={{ key, price: bundlesOfType[key] }}
                                                            typeId={currentType.id}
                                                        />
                                                        <span>&nbsp;{`=`}&nbsp;</span>
                                                        <span className={unpaidModalClasses.total}>
                                                            ${subTotal.toFixed(2)}
                                                        </span>
                                                    </div>
                                                )
                                            }
                                        }                                        
                                        return bundleCollector
                                    }, [])}
                                    {additionalInfo(currentTypeId).length > 0 && (
                                        <>
                                            (
                                            {additionalInfo(currentTypeId).map((info, i) => {
                                                const space = i !== additionalInfo(currentTypeId).length-1 ? ' ' : ''
                                                return `${info}${space}`
                                            })}
                                            )
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                        <hr />
                    </Fragment>
                )
                return creditsCollector
            }, [])}
            {!isEmpty(discounts) && <Discount vipCheckout discounts={discounts} />}
            {groupSessionsUnpaidTotal ? (
                <GroupSessionsPriceList groupSessionsUnpaidTotal={groupSessionsUnpaidTotal} />
            ) : null}
            {allowCheckout && (
                <h2
                    className={classNames(
                        'align-right',
                        unpaidModalClasses.grandTotal,
                        unpaidModalClasses.colorPulse,
                        {
                            [unpaidModalClasses.colorPulseAnimate]:
                            unpaidAdmin && tempCreditsActive && !simpleTally
                        }
                    )}>{`${textConstants.GRAND_TOTAL}: $${
                    tempCredits && tempCreditsActive
                        ? tempTotalFinal.toFixed(2)
                        : grandTotal.toFixed(2)
                }`}</h2>
            )}
        </>
    )
}

export default UnpaidList
