import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ColorChangingSpan from './ColorChangingSpan/ColorChangingSpan'
import { getItemPrice } from 'training-lane-booking-shared/utilities/functions'
import textConstants from 'training-lane-booking-shared/utilities/text'
import groupSessionPriceListClasses from './GroupSessionPriceList.module.scss'

const GroupSessionsPriceList = ({ groupSessionsUnpaidTotal = 0 }) => {
    const dispatch = useDispatch()
    
    const tallyIncludeGroupSessions = useSelector(state => state.slotPicker.tallyIncludeGroupSessions)
    
    const handleInclChecked = () => {
        dispatch.slotPicker.setTallyIncludeGroupSessions(!tallyIncludeGroupSessions)
    }
    
    const spanList = [
        {
            className: groupSessionPriceListClasses.title,
            text: `${textConstants.GROUP_SESSION_PRICE_INFO}: `
        },
        {
            className: groupSessionPriceListClasses.price,
            text: `$${groupSessionsUnpaidTotal.toFixed(2)}`
        },
        {
            text: ` + ${textConstants.PURCHASE_SESSION_HST} = `
        },
        {
            className: groupSessionPriceListClasses.total,
            text: `$${getItemPrice(groupSessionsUnpaidTotal)}`
        }
    ]
    
    return (
        <div className={groupSessionPriceListClasses.wrapper}>
            <span>{`${textConstants.ADD} `}</span>
            <input
                type="checkbox"
                checked={tallyIncludeGroupSessions}
                onChange={handleInclChecked}
            />{' '}
            {
                spanList.map((span, i) => (
                    <ColorChangingSpan
                        key={i}
                        condition={tallyIncludeGroupSessions}
                        className={span.className}>
                        {span.text}
                    </ColorChangingSpan>
                ))
            }
        </div>
    )
}

export default GroupSessionsPriceList