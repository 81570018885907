import { graphql, useStaticQuery } from 'gatsby'

export const useBackgroundImages = () => {
    return useStaticQuery(graphql`
        {
            backgrounds: allFile(filter: {sourceInstanceName: {eq: "backgrounds"}}) {
                nodes {
                    relativePath
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
            }
        }
    `)
}
