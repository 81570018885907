import React from 'react'
import isEmpty from 'lodash/isEmpty'
import textConstants from 'training-lane-booking-shared/utilities/text'
import discountClasses from './Discount.module.scss'

const formatCount = count => `- ${count} x`
const formatTotal = total => ` = - $${total}`

const Discount = ({ vipCheckout = false, discounts = {} }) => {
    const showDiscountTitle = !vipCheckout || (vipCheckout && !isEmpty(discounts))
    const showDiscountLanes = !vipCheckout || (vipCheckout && discounts.dailyLanes)
    const showDiscountIr = !vipCheckout || (vipCheckout && discounts.weeklyIrs)
    let dailyLanesCount = vipCheckout ? formatCount(discounts.dailyLanes) : '-'
    let dailyLanesTotal = vipCheckout ? formatTotal(discounts.dailyLanesTotal) : ''
    let weeklyIrCount = vipCheckout ? formatCount(discounts.weeklyIrs) : '-'
    let weeklyIrTotal = vipCheckout ? formatTotal(discounts.weeklyIrsTotal) : ''
    return (
        <>
            <div>
                { showDiscountTitle && <div className={discountClasses.discountTitle}>{textConstants.DISCOUNT_AMBASSADORS}</div> }
                { showDiscountLanes && <div className={discountClasses.discountLine}>{dailyLanesCount} {textConstants.DISCOUNT_FREE_SESSION_PER_DAY}{dailyLanesTotal}</div> }
                { showDiscountIr && <div className={discountClasses.discountLine}>{weeklyIrCount} {textConstants.DISCOUNT_FREE_IR_PER_WEEK}{weeklyIrTotal}</div> }
            </div>
            <hr />
        </>
    )
}

export default Discount
