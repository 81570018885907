import { graphql, useStaticQuery } from 'gatsby'

export const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        title
                        description
                        canonicalUrl
                        menuLinks {
                            name
                            link
                        }
                        subMenuLinks {
                            me {
                                name
                                link
                            }
                            admin {
                                name
                                link
                            }
                        }
                    }
                }
            }
        `
    )
    return site.siteMetadata
}
