import React, { useState } from 'react'
import classNames from 'classnames'
import { Detector } from 'react-detect-offline'
import textConstants from 'training-lane-booking-shared/utilities/text'
import offlineComponentClasses from './OfflineComponent.module.scss'

const OfflineComponent = () => {
    const [hide, setHide] = useState(true)
    const classes = (online, hide) => classNames(
        offlineComponentClasses.hvrBackPulse,
        offlineComponentClasses.hvrBackPulseAnimate,
        {
            [offlineComponentClasses.hvrBackPulseOfflineAnimate]: !online,
            [offlineComponentClasses.hvrBackPulseOnlineAnimate]: online,
            [offlineComponentClasses.hide]: hide
        }
    )
    return (
        <Detector
            polling={{ url: process.env.SITE_URL }}
            onChange={state => {
                if (state) {
                    setTimeout(() => {
                        setHide(true)
                    }, 3000)
                } else {
                    setHide(false)
                }
            }}
            render={
                ({ online }) => (
                    <div className={classes(online, hide)}>
                        { online ? textConstants.OFFLINE_ONLINE : textConstants.OFFLINE_OFFLINE }
                    </div>
                )
            }
        />
    )
}

export default OfflineComponent