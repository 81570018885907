import React from 'react'
import Popup from 'reactjs-popup'
import { connect } from 'react-redux'
import modalClasses from './Modal.module.scss'
import textConstants from 'training-lane-booking-shared/utilities/text'

const Modal = ({
    children,
    name,
    open,
    nested = false,
    larger = false,
    className = null,
    closeOnEscape = true,
    closeOnDocumentClick = false,
    showBackButton = false,
    disableBackButtonDefaultClickEvent = false,
    handleBackButtonClick = () => {},
    showDoneButton = true,
    disableDoneButtonDefaultClickEvent = false,
    handleDoneButtonClick = () => {},
    modalState,
    setModalState,
    handleClose = () => {}
}) => {
    // noinspection JSUnusedGlobalSymbols
    let popupProps = {
        open: open ?? modalState[name]?.state,
        nested,
        onClose: () => {
            handleClose()
            setModalState(name, { state: false })
        },
        modal: true,
        lockScroll: true,
        closeOnEscape: disableBackButtonDefaultClickEvent || disableDoneButtonDefaultClickEvent
            ? false
            : closeOnEscape,
        closeOnDocumentClick
    }
    if (nested) {
        className = 'nested-popup'
    }
    if (larger) {
        className = 'larger-popup'
    }
    if (className !== null) {
        popupProps = { className, ...popupProps }
    }
    return (
        <Popup {...popupProps}>
            <div className={modalClasses.modal}>
                {children}
                {showBackButton && (
                    <div className={modalClasses.backButtonWrapper}>
                        <button
                            className={modalClasses.back}
                            onClick={() => {
                                disableBackButtonDefaultClickEvent
                                    ? handleBackButtonClick()
                                    : setModalState(name, { state: false })
                            }}>
                            {'<'}
                        </button>
                    </div>
                )}
                {showDoneButton && (
                    <div className={modalClasses.closeButtonWrapper}>
                        <button
                            className={modalClasses.close}
                            onClick={() => {
                                disableDoneButtonDefaultClickEvent
                                    ? handleDoneButtonClick()
                                    : setModalState(name, { state: false })
                            }}>
                            {textConstants.DONE}
                        </button>
                    </div>
                )}
            </div>
        </Popup>
    )
}

const mapState = state => ({
    modalState: state.utils.modalState
})

const mapDispatch = dispatch => ({
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload)
})

export default connect(mapState, mapDispatch)(Modal)
