import React from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import capitalize from 'lodash/capitalize'
import { useIsSmallerThan } from 'src/hooks/useIsSmallerThan'
import CounterContainer from 'src/containers/CounterContainer/CounterContainer'
import CounterContainerShared
    from 'training-lane-booking-shared/containers/CounterContainerShared/CounterContainerShared'
import Logo from 'src/components/UI/Logo/Logo'
import { filterTypesByCapabilities } from 'training-lane-booking-shared/utilities/functions'
import { roles, types } from 'training-lane-booking-shared/utilities/constants'
import headerClasses from './Header.module.scss'

const Header = ({
    credits,
    pageTitle
}) => {
    const isXSmall = useIsSmallerThan(480)
    const isSmall = useIsSmallerThan(1280)
    // noinspection JSUnresolvedVariable
    const roleId = useSelector(state => state.firebase.profile.roleId)
    // noinspection JSUnresolvedVariable
    const capabilities = useSelector(state => state.auth.capabilities)
    const filteredTypesLength = filterTypesByCapabilities(types, capabilities, true)
        .length
    const isStaffOrAdmin = [roles.staff.id, roles.admin.id].includes(roleId)

    return (
        <section className={headerClasses.header}>
            <div className="grid-wrapper">
                {!isXSmall && (
                    <Logo filteredTypesLength={filteredTypesLength} />
                )}
                {
                    
                    <>
                        {
                            !isXSmall && (
                                <div
                                    className={classNames(`col-${isStaffOrAdmin
                                        ? isSmall
                                            ? 10
                                            : 8
                                        : 2}`, headerClasses.headerMiddleText)}>
                                    {capitalize(pageTitle)}
                                </div>
                            )
                        }
                        <div
                            className={`col-${
                                isXSmall ? 10 - filteredTypesLength : 1
                            } large-visible`}
                        />
                        {
                            isStaffOrAdmin ? null : (
                                <CounterContainerShared
                                    CounterContainer={CounterContainer}
                                    credits={credits}
                                    capabilities={capabilities}
                                />
                            )
                        }
                    </>
                }
            </div>
        </section>
    )
}

export default Header
