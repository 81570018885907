import React from 'react'
import Cookies from 'js-cookie'
import Modal from 'src/components/UI/Modal/Modal'
import textConstants from 'training-lane-booking-shared/utilities/text'

const DownloadMobileAppModal = () => (
    <Modal
        name='downloadMobileApp'
        handleClose={() => { Cookies.set('downloadMobileDismissed', 1, { expires: 30 }) }}
        nested>
        <div className="align-center">{textConstants.DOWNLOAD_MOBILE_APP}</div>
        <br />
        <div className="align-center grid-wrapper">
            <div className="col-6" style={{ marginTop: 10 }}>
                <a className="store-logo" target="_blank" rel="noopener noreferrer" href="https://apps.apple.com/us/app/training-lane/id1555785288?itsct=apps_box_badge&amp;itscg=30200">
                    <img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1625356800&h=c6f56e7948b97076ed602d31c6b0a9c8" alt="Download on the App Store" />
                </a>
            </div>
            <div className="col-6">
                <a target="_blank" rel="noopener noreferrer" href='https://play.google.com/store/apps/details?id=com.traininglanemobile&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                    <img alt='Get it on Google Play' height={60} src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                </a>
            </div>
        </div>
    </Modal>
)

export default DownloadMobileAppModal
