import React from 'react'
import classNames from 'classnames'
import { getTypeId } from 'training-lane-booking-shared/utilities/functions'
import textConstants from 'training-lane-booking-shared/utilities/text'
import counterClasses from './Counter.module.scss'

const Wrapper = ({ children, landingPage }) => landingPage
    ? <div className={counterClasses.counterLabelLong}>{children}</div>
    : (
        <>
            <span className={counterClasses.counterLabel}>{children}</span>
            <br />
        </>
    )

const Counter = ({
    adminView = false,
    creditsLoading = false,
    credits,
    type,
    handleCounterClick = () => {},
    shouldCounterAnimate = false,
    tempCreditActive = false,
    isStaff = false,
    typesLength = 4,
    disabled = false,
    landingPage = false
}) => {
    const buttonClasses = classNames(
        'button', counterClasses.bcButton, counterClasses.hvrRippleOut,
        {
            [counterClasses.hvrRippleOutBlue]: type.category === 'lane',
            [counterClasses.hvrRippleOutChelle]: type.id === getTypeId('tlc'),
            [counterClasses.hvrRippleOutRed]: type.category === 'ir' && type.id !== 'tlc',
            [counterClasses.hvrRippleOutAnimate]: shouldCounterAnimate,
            [counterClasses.hvrBackPulse]: tempCreditActive,
            [counterClasses.hvrBackPulseAnimateBlue]: type.category === 'lane' && tempCreditActive,
            [counterClasses.hvrBackPulseAnimateChelle]: type.id === getTypeId('tlc'),
            [counterClasses.hvrBackPulseAnimateRed]: type.category === 'ir' && type.id !== 'tlc' && tempCreditActive,
            [counterClasses.hvrBackPulseAnimate]: tempCreditActive && !isStaff,
            'disabled': creditsLoading || disabled
        }
    )

    const counterLabel = (landingPageProp, typeProp) => {
        if (landingPageProp) {
            const meLabel = textConstants.ME_TITLE

            if (typeProp.id === getTypeId('l12')) {
                return `${meLabel} + 1`
            }

            if (typeProp.id === getTypeId('l3')) {
                return `${meLabel} + 2`
            }
        }

        return type.label.short
    }
    
    // noinspection JSCheckFunctionSignatures
    return (
        <div
            className={classNames(
                {
                    'col-1': !adminView,
                    'col-2': adminView && typesLength >= 6,
                    'col-ten-2': adminView && typesLength === 5,
                    'col-3': adminView && typesLength === 4
                },
                counterClasses.counterWrapper,
                landingPage && counterClasses.counterWrapperLandingPage
            )}
            onClick={e => handleCounterClick(e, type)}
            onKeyUp={handleCounterClick}
            role="button"
            tabIndex="-1">
            <Wrapper landingPage={landingPage}>
                {counterLabel(landingPage, type)}
            </Wrapper>
            <button className={buttonClasses}>
                {creditsLoading || disabled ? 0 : credits[type.id] || 0}
            </button>
        </div>
    )
}

export default Counter
