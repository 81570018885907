import React from 'react'
import { themeColors } from 'training-lane-booking-shared/utilities/constants'

const Strikethrough = ({ oldText, newText }) => (
    <>
        <s>{oldText}</s>{' '}
        <span style={{ color: themeColors.brandRed }}>{newText}</span>
    </>
)

export default Strikethrough