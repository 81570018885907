import React from 'react'
import Modal from 'src/components/UI/Modal/Modal'
import textConstants from 'training-lane-booking-shared/utilities/text'

const UsePortraitModal = ({ showUsePortraitModal }) => (
    <Modal
        open={showUsePortraitModal}
        nested
        closeOnEscape={false}
        showDoneButton={false} >
        <div className="align-center">{textConstants.USE_PORTRAIT}</div>
    </Modal>
)

export default UsePortraitModal
