import React, { useEffect, useState } from 'react'
import AnimateHeight from 'react-animate-height'

const AnimatedContainer = ({
    children,
    animatedContainerId,
    containerClassName,
    startEndHeight = [0, 'auto']
}) => {
    const [height, setHeight] = useState(startEndHeight[0])
    
    useEffect(() => {
        // noinspection JSCheckFunctionSignatures
        setHeight(startEndHeight[1])
        return () => setHeight(startEndHeight[0])
    }, [startEndHeight])
    
    // noinspection JSValidateTypes
    return (
        <AnimateHeight
            id={animatedContainerId}
            easing='ease-out'
            animateOpacity
            height={height}
            style={{transform: 'translate3d(0,0,0)', 'WebkitTransform': 'translate3d(0,0,0)'}}
        >
            <div className={containerClassName}>
                { children }
            </div>
        </AnimateHeight>
    )
}

export default AnimatedContainer