import React, { Fragment } from 'react'
import Modal from 'src/components/UI/Modal/Modal'
import faqModalClasses from './FaqModal.module.scss'
import faqObj from 'training-lane-booking-shared/utilities/faqObj'
import textConstants from 'training-lane-booking-shared/utilities/text'

const FaqModal = () => (
    <Modal name='faq'>
        <h1>{textConstants.FAQ}</h1>
        <div className={faqModalClasses.faqModalWrapper}>
        {
            faqObj.map((faq, i) => (
                <Fragment key={i}>
                    <p className={faqModalClasses.faqModalQuestion}>{faq.q}</p>
                    {
                        faq.a.map((a, j) => <div key={j}>{a}</div>)
                    }
                </Fragment>
            ))
        }
        </div>
    </Modal>
)

export default FaqModal
