import React from 'react'
import purchaseModalClasses from 'src/components/Modals/PurchaseModal/PurchaseModal.module.scss'
import classNames from 'classnames'
import PurchasePickedInfoShared from 'training-lane-booking-shared/components/Purchase/PurchasePickedInfoShared/PurchasePickedInfoShared'
import PurchasePickedInfo from '../PurchasePickedInfo/PurchasePickedInfo'
import Strikethrough from 'src/components/UI/Strikethrough/Strikethrough'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import { getTypeId } from 'training-lane-booking-shared/utilities/functions'
import textConstants from 'training-lane-booking-shared/utilities/text'
import bundleListItemClasses from './BundleListItem.module.scss'

const BundleListItem = ({
    bundleKey,
    price,
    total,
    itemType,
    itemData,
    handleBuyClick,
    pendingPaymentRequests
}) => (
    <div className={purchaseModalClasses.bundleWrapper}>
        <div
            className={classNames('grid-wrapper', purchaseModalClasses.bundle)}>
            <div className={classNames(handleBuyClick ? 'col-8' : 'col-12', purchaseModalClasses.bundleText)}>
                <PurchasePickedInfoShared
                    PurchasePickedInfo={PurchasePickedInfo}
                    Strikethrough={Strikethrough}
                    bundle={{ key: bundleKey, price }}
                    total={total}
                    typeId={itemType.id}
                />
                <br />
                {
                    pendingPaymentRequests.length ? (
                        <div className={bundleListItemClasses.pending}>
                            {pendingPaymentRequests.length}
                            {' '}
                            {textConstants.PRI_PENDING_PAYMENT.toLowerCase()}
                        </div>
                    ) : null
                }
            </div>
            {
                handleBuyClick &&
                <div className="col-4 align-right">
                    <Confirm
                        centerButton={false}
                        buttons={
                            [
                                {
                                    buttonClasses: itemType.id === getTypeId('tlc')
                                        ? confirmClasses.confirmButtonProceedChelle
                                        : itemType.category === 'lane'
                                            ? confirmClasses.confirmButtonProceedSafe
                                            : confirmClasses.confirmButtonProceedDanger,
                                    handleFn: () => handleBuyClick(itemData),
                                    label: textConstants.PURCHASE_BUTTON_BUY_NOW
                                }
                            ]
                        }
                    />
                </div>
            }
        </div>
        <hr />
    </div>
)

export default BundleListItem
