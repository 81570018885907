import React from 'react'
import classNames from 'classnames'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useHeaderImagesSmall } from 'src/hooks/useHeaderImagesSmall'
import { useHeaderImagesLarge } from 'src/hooks/useHeaderImagesLarge'
import logoClasses from './Logo.module.scss'

const Logo = ({ landingPage = false, filteredTypesLength = 0 }) => {
    const imagesSmall = useHeaderImagesSmall()
    const imagesLarge = useHeaderImagesLarge()
    
    const getImageByFileName = (image, fileName) => image.find(
        img => {
            const { name, ext } = img.node.parent
            const imgFileName = name + ext
            
            return imgFileName.includes(fileName)
        }
    ).node.gatsbyImageData
    
    const highLogo = getImageByFileName(imagesLarge, 'TrainingLane_logo_transparent.png')
    
    const smallLogo = getImageByFileName(imagesSmall, 'TrainingLane_logo_transparent.png')
    
    // noinspection SpellCheckingInspection
    const largeLogo = getImageByFileName(imagesLarge, 'TrainingLane_logo_withtextright_whitebg.jpg')
    
    const colNum = size => {
        if (landingPage) {
            return size === 'large' ? 3 : 2
        }
        return (size === 'large' ? 8 : filteredTypesLength ? 7 : 2) - filteredTypesLength
    }
    
    return ['small', 'large'].map((size, i) => (
        <div
            key={i}
            className={classNames(
                logoClasses.logo,
                logoClasses[size],
                `col-${colNum(size)}`,
                `large-${size === 'large' ? 'hidden' : 'visible'}`,
                'align-left',
                {
                    [logoClasses.high]: landingPage
                }
            )}>
            <GatsbyImage
                image={landingPage ? highLogo : size === 'large' ? largeLogo : smallLogo}
                loading="lazy"
                alt={`Logo ${size}`} />
        </div>
    ))
}

export default Logo