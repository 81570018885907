import { graphql, useStaticQuery } from 'gatsby'

export const useHeaderImagesSmall = () => {
    const images = useStaticQuery(
        graphql`
            query HeaderImagesSmall {
                allImageSharp {
                    edges {
                        node {
                            gatsbyImageData(height: 20, layout: FIXED)
                            parent {
                                ... on File {
                                    name
                                    ext
                                }
                            }
                        }
                    }
                }
            }
        `
    )
    // noinspection JSUnresolvedVariable
    return images.allImageSharp.edges
}
