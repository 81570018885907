import React from 'react'
import { Cross as Hamburger } from 'hamburger-react'
import hamburgerMenuClasses from './HamburgerMenu.module.scss'
import { connect } from 'react-redux'

const HamburgerMenu = ({ modalStateMenu, setModalState }) => {
    return (
        <div className={hamburgerMenuClasses.hamburgerMenuWrapper}>
            <Hamburger
                distance='sm'
                duration={0.2}
                size={20}
                toggled={modalStateMenu}
                toggle={boolean => setModalState('menu', { state: boolean })} />
        </div>
    )
}

const mapState = state => ({
    modalStateMenu: state.utils.modalState.menu.state
})

const mapDispatch = dispatch => ({
    setModalState: (modalName, payload) => dispatch.utils.setModalState(modalName, payload)
})

export default connect(mapState, mapDispatch)(HamburgerMenu)