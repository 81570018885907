import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import classNames from 'classnames'
import PurchasePickedTitle from 'src/components/Purchase/PurchasePickedTitle/PurchasePickedTitle'
import PurchasePickedInfo from 'src/components/Purchase/PurchasePickedInfo/PurchasePickedInfo'
import Strikethrough from 'src/components/UI/Strikethrough/Strikethrough'
import Modal from 'src/components/UI/Modal/Modal'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import PurchasePickedInfoShared from 'training-lane-booking-shared/components/Purchase/PurchasePickedInfoShared/PurchasePickedInfoShared'
import textConstants from 'training-lane-booking-shared/utilities/text'
import paymentProcessorModalClasses from './PaymentProcessorModal.module.scss'

const PaymentProcessorModal = ({
    selectedItem,
    paymentRequests,
    updatePaymentRequest,
    setModalState,
    log
}) => {
    const [confirmed, setConfirmed] = useState(false)
    const [usersPendingRequests, setUsersPendingRequests] = useState([])

    const firebase = useFirebase()

    useEffect(() => {
        setUsersPendingRequests(
            paymentRequests.filter(
                pr => pr.typeId === selectedItem.itemType.id && pr.status === 'unpaid'
            )
        )
    }, [paymentRequests, selectedItem.itemType.id])

    const handleConfirm = async () => {
        await updatePaymentRequest(firebase)
            .then(() => setConfirmed(true))
            .catch(error => {
                log({ message: 'Error in PaymentProcessorModal - handleConfirm:', error, firebase })
            })
    }

    return (
        <Modal
            name="paymentProcessor"
            nested
            handleClose={() => setConfirmed(false)}>
            <Confirm
                newLineAboveButtons={false}
                heading={
                    confirmed ? (
                        <>
                            <div>{textConstants.PRI_RECEIVED}</div>
                            <div>{textConstants.PRI_THANK_YOU}</div>
                        </>
                    ) : (
                        <>
                            <div>{textConstants.PURCHASE_CONFIRM_HEADING}</div>
                            <br />
                            <PurchasePickedTitle itemType={selectedItem.itemType} />
                            <br />
                            <PurchasePickedInfoShared
                                PurchasePickedInfo={PurchasePickedInfo}
                                Strikethrough={Strikethrough}
                                bundle={selectedItem.bundle}
                                total={selectedItem.total}
                                typeId={selectedItem.itemType.id}
                            />
                            {usersPendingRequests.length ? (
                                <div
                                    className={classNames(
                                        'spacer',
                                        paymentProcessorModalClasses.attentionPending
                                    )}>
                                    {textConstants.PRI_PENDING_PAYMENT_REQUESTS_INFO}
                                </div>
                            ) : null}
                        </>
                    )
                }
                buttons={[
                    confirmed
                        ? {
                            buttonClasses: confirmClasses.confirmButtonProceedSafe,
                            handleFn: () =>
                                setModalState('paymentProcessor', { state: false }),
                            label: textConstants.DISMISS
                        }
                        : {
                            buttonClasses: confirmClasses.confirmButtonProceedDanger,
                            handleFn: handleConfirm,
                            label: textConstants.PROCEED
                        }
                ]}
            />
        </Modal>
    )
}

const mapState = state => ({
    selectedItem: state.payments.selectedItem,
    paymentRequests: state.firestore.ordered.paymentRequests
})

const mapDispatch = dispatch => ({
    updatePaymentRequest: firebase =>
        dispatch.payments.updatePaymentRequest({ firebase }),
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload),
    log: payload => dispatch.utils.log(payload)
})

export default connect(mapState, mapDispatch)(PaymentProcessorModal)
