import React from 'react'
import { connect } from 'react-redux'
import capitalize from 'lodash/capitalize'
import classNames from 'classnames'
import { useIsSmallerThan } from 'src/hooks/useIsSmallerThan'
import HamburgerMenu from 'src/components/UI/HamburgerMenu/HamburgerMenu'
import MenuModal from 'src/components/Modals/MenuModal/MenuModal'
import MainNav from 'src/containers/TopStripe/MainNav/MainNav'
import Welcome from 'src/containers/TopStripe/Welcome/Welcome'
import Logo from 'src/components/UI/Logo/Logo'
import topStripeClasses from './TopStripe.module.scss'
import { getFirstName } from 'training-lane-booking-shared/utilities/functions'

const TopStripe = ({ location, menuLinks, landingPage, pageTitle, displayName }) => {
    const isSmall = useIsSmallerThan(736)
    const isXSmall = useIsSmallerThan(480)

    const firstName = getFirstName(displayName)
    
    const middleText = landingPage ? <Welcome firstName={firstName} /> : capitalize(pageTitle)
    
    return (
        <section className={classNames(
            landingPage
                ? topStripeClasses.topStripeWrapperLandingPage
                : topStripeClasses.topStripeWrapper
        )}>
                <div className={classNames(topStripeClasses.fullWidth, 'grid-wrapper')}>
                    <MenuModal location={location} />
                    {(isXSmall || landingPage) && (
                        <>
                            <Logo landingPage={landingPage} />
                            <div
                                className={classNames(
                                    topStripeClasses.headerMiddleText,
                                    'col-8',
                                    'align-center',
                                    {
                                        [topStripeClasses.headerMiddleTextLandingPage]: landingPage
                                    }
                                )}>
                                {middleText}
                            </div>
                        </>
                    )}
                    {isSmall ?
                        (
                            <div
                                className={classNames(topStripeClasses.hamburger, {
                                    ['col-2']: isXSmall
                                })}>
                                <HamburgerMenu />
                            </div>
                        ) : (
                            <div className={topStripeClasses.topStripe}>
                                <MainNav
                                    inline
                                    menuLinks={menuLinks}
                                    location={location}
                                    activeClass={topStripeClasses.topStripeActiveMenu}
                                />
                            </div>
                        )}
                </div>
        </section>
    )
}

const mapState = state => ({
    pageTitle: state.utils.pageTitle,
    displayName: state.firebase.profile.displayName ?? ''
})

export default connect(mapState)(TopStripe)