import React from 'react'
import Layout from './src/containers/Layout/Layout'
import store from 'src/rematch'
import wrapWithProvider from "./wrap-with-provider"

export const wrapPageElement = ({ element, props }) => (
    <Layout {...props} landingPage={props.path === '/'} onGrid={props.path === '/booking'}>{element}</Layout>
)

export const wrapRootElement = wrapWithProvider

export const onRouteUpdate = ({ location }) => {
    let newPathName = location.pathname.split('/')[1]
    if (newPathName.length === 0 || !['booking', 'me', 'admin'].includes(newPathName)) {
        newPathName = ''
    }
    // noinspection JSUnresolvedVariable
    store.dispatch.utils.updatePageTitle(newPathName)
}
