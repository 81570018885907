import React from 'react'
import purchasePickedInfoClasses from './PurchasePickedInfo.module.scss'

const PurchasePickedInfo = ({ bundleTextBold, bundleTextAddl, compactText, itemTypeColor }) =>
    compactText.length ? (
        <span
            className={purchasePickedInfoClasses.bundleTitle}
            style={{ color: itemTypeColor }}>{compactText}</span>
    ) : (
        <>
            <span className={purchasePickedInfoClasses.bundleTitle}>{bundleTextBold}</span>
            <span>{bundleTextAddl}</span>
        </>
    )

export default PurchasePickedInfo
