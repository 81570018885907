import { useState, useEffect } from 'react'

export const useFirebaseFunctions = () => {
    const [firebase, setFirebase] = useState(null)
    // noinspection JSCheckFunctionSignatures
    useEffect(() => {
        let isSubscribed = true
        const app = import('firebase/compat/app')
        const functions = import('firebase/compat/functions')
        // eslint-disable-next-line no-undef
        Promise.all([app, functions]).then(([firebase]) => {
            if (process.env.NODE_ENV === 'development') {
                firebase.default.functions().useEmulator("localhost", 5001)
            }
            if (isSubscribed) {
                setFirebase(firebase.default)
            }
        })
        return () => isSubscribed = false
    }, [])

    return firebase
}