import React from 'react'
import { useTrainingLaneLogo } from 'src/hooks/useTrainingLaneLogo'
import { GatsbyImage } from 'gatsby-plugin-image'
import textConstants from 'training-lane-booking-shared/utilities/text'

const UnsupportedVersion = ({ versionInfo }) => {
    const trainingLaneLogo = useTrainingLaneLogo()

    return (
        <div className="blocked-wrapper">
            <GatsbyImage style={{ margin: '0 auto'}} image={trainingLaneLogo} loading="lazy" alt="Logo" />
            <p>
                {textConstants.UNSUPPORTED_VERSION_INFO_WEB}
            </p>
            <p>{`${textConstants.UNSUPPORTED_VERSION_YOUR_VERSION}: v${versionInfo.currentVersion}`}</p>
            <p>{`${textConstants.UNSUPPORTED_VERSION_MIN_VERSION}: v${versionInfo.minVersion}`}</p>
            <p>{textConstants.UNSUPPORTED_VERSION_CALL_TO_ACTION_WEB}</p>
        </div>
    )
}

export default UnsupportedVersion