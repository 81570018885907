import React from 'react'
import { Link } from 'gatsby'
import { connect, useSelector } from 'react-redux'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import { actionTypes } from 'redux-firestore'
import queryString from 'query-string'
import { useIsLoggedIn } from 'training-lane-booking-shared/hooks/useIsLoggedIn'
import { roles } from 'training-lane-booking-shared/utilities/constants'

const MainNav = ({
     inline = false,
     menuLinks,
     location,
     capabilities,
     logout,
     setModalState,
     activeClass = ''
 }) => {
    const firebase = useFirebase()
    const params = queryString.parse(location.search)
    // noinspection JSUnresolvedVariable
    const isStaffOrAdmin = useSelector(state =>
        [roles.staff.id, roles.admin.id].includes(state.firebase.profile.roleId)
    )
    const firestore = useFirestore()
    const isLoggedIn = useIsLoggedIn()

    const isPartiallyActive = menuName => {
        const mainPage = location.pathname.split('/')[1]
        return mainPage.includes(menuName) ||
        (mainPage.length === 0 && menuName === 'home')
            ? { className: activeClass }
            : {}
    }

    const handleButtonClick = async menu => {
        setModalState('menu', { state: false })
        if (
            menu === 'login' ||
            menu === 'purchase' ||
            menu === 'news' ||
            menu === 'faq' ||
            menu === 'legend'
        ) {
            let modalProps = { state: true }
            if (menu === 'purchase') {
                Object.assign(modalProps, { props: { calledBy: 'menu' } })
            }
            setModalState(menu, { ...modalProps })
        }
        if (menu === 'logout') {
            logout({ firebase, firestore, actionTypes })
        }
    }

    const Wrapper = ({ children, ...props }) =>
        inline ? (
            <span {...props} className="inline-item">{children}</span>
        ) : (
            <div {...props} className="col-1">{children}</div>
        )

    return menuLinks.reduce((menus, menu, i) => {
        if (
            (params.mode?.length === 0 && menu.name === 'login' && !isLoggedIn) ||
            (menu.name === 'logout' && isLoggedIn) ||
            (menu.name === 'purchase' && capabilities.canPrePay) ||
            menu.name === 'news' ||
            menu.name === 'faq' ||
            (menu.name === 'legend' && isStaffOrAdmin && location.pathname.includes('booking'))
        ) {
            menus.push(
                <Wrapper key={i}>
                    <button
                        className="flat-button menu-button"
                        onClick={() => handleButtonClick(menu.name)}>
                        {menu.name}
                    </button>
                </Wrapper>
            )
        } else if (
            menu.name !== 'legend' &&
            menu.name !== 'purchase' &&
            ((params.mode?.length !== 0 && menu.name === 'login' && !isLoggedIn) ||
                (menu.name === 'admin' && isStaffOrAdmin && isLoggedIn) ||
                (menu.name === 'me' && isLoggedIn) ||
                (menu.name !== 'admin' &&
                    menu.name !== 'me' &&
                    ((params.mode?.length === 0 && menu.name === 'login') ||
                        menu.name !== 'login') &&
                    menu.name !== 'logout'))
        ) {
            menus.push(
                <Wrapper key={i}>
                    <Link
                        to={menu.name === 'login' ? '/' : menu.link}
                        onClick={() =>
                            menu.name === 'login'
                                ? setModalState('login', { state: true })
                                : setModalState('menu', { state: false })
                        }
                        getProps={() => isPartiallyActive(menu.name)}>
                        {menu.name}
                    </Link>
                </Wrapper>
            )
        }
        return menus
    }, [])
}

const mapState = state => ({
    capabilities: state.auth.capabilities
})

const mapDispatch = dispatch => ({
    logout: payload => dispatch.auth.logout(payload),
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload)
})

export default connect(mapState, mapDispatch)(MainNav)
