// noinspection JSValidateTypes

import React from "react"
import { Provider, useSelector } from "react-redux"
import { getPersistor } from '@rematch/persist'
import { PersistGate } from 'redux-persist/lib/integration/react'
import { ReactReduxFirebaseProvider, isLoaded } from 'react-redux-firebase'
import { createFirestoreInstance as firestoreInstance } from 'redux-firestore'
import firebase from 'firebase/compat/app'
import store from 'src/rematch'
import LogoLoading from 'src/components/UI/LogoLoading/LogoLoading'
import { zeroCredits, defaultUserData } from 'training-lane-booking-shared/utilities/constants'
import { timeStamp } from 'training-lane-booking-shared/utilities/functions'

const persistor = getPersistor()

const onBeforeLift = () => {
    // console.log('PersistGate.onBeforeLift')
}

// noinspection JSUnusedGlobalSymbols
const rrfConfig = {
    userProfile: 'users',
    enableClaims: true,
    useFirestoreForProfile: true,
    profileFactory: (userData, profileData) => {
        return {
            ...defaultUserData,
            createdBy: userData.uid,
            createdTime: timeStamp(),
            credits: { ...zeroCredits },
            nonRefundableCredits: { ...zeroCredits },
            promoCredits: { ...zeroCredits },
            ...profileData
        }
    }/**, <--- comma!
     * Presence (and sessions) only work with Realtime Database
     * TODO: for later: consider implementing this
     * @see https://firebase.google.com/docs/firestore/solutions/presence
     * presence: 'presence',
     * sessions: 'sessions'
     */
}

export const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance: firestoreInstance
}

const AuthIsLoaded = ({ children }) => {
    // noinspection JSUnresolvedVariable
    const auth = useSelector(state => state.firebase.auth)
    if (!isLoaded(auth)) return <LogoLoading />
    return children
}

// eslint-disable-next-line react/display-name
export default ({ element }) => (
    <Provider store={store}>
        <PersistGate
            loading={<LogoLoading />}
            onBeforeLift={onBeforeLift}
            persistor={persistor}>
            <ReactReduxFirebaseProvider {...rrfProps}>
                <AuthIsLoaded>
                    {element}
                </AuthIsLoaded>
            </ReactReduxFirebaseProvider>
        </PersistGate>
    </Provider>
)
