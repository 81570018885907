import React from 'react'
import cloneDeep from 'lodash/cloneDeep'
import capitalize from 'lodash/capitalize'
import classNames from 'classnames'
import Modal from 'src/components/UI/Modal/Modal'
import { types } from 'training-lane-booking-shared/utilities/constants'
import textConstants from 'training-lane-booking-shared/utilities/text'
import legendModalClasses from './LegendModal.module.scss'

const LegendModal = () => {
    let typesClone = cloneDeep(types)
    
    typesClone.splice(0, 0, {
        id: 'admin',
        description: {
            categoryAlt: 'Admin & Staff'
        }
    }, {
        id: 'vipl12',
        description: {
            categoryAlt: 'VIP Training Lanes',
            people: 'One / Two People'
        }
    }, {
        id: 'vipl3',
        description: {
            categoryAlt: 'VIP Training Lanes',
            people: 'Three People'
        }
    })
    
    return (
        <Modal name='legend'>
            <h1>Legend</h1>
            <>
                <h2>{textConstants.ADMIN_BOOKING_COLORS_LEGEND}</h2>
                {
                    typesClone.reduce((typeCollector, type, i) => {
                        const bounceColor = `legendModalItem${capitalize(type.id)}`
                        const bgClassName = classNames(
                            legendModalClasses.legendModalItem,
                            { [legendModalClasses[bounceColor]]: true }
                        )
                        typeCollector.push(
                            <div key={i}
                                 className={bgClassName}>{type.description.categoryAlt ?? type.description.category}{type.description.people && ` - ${type.description.people}`}</div>
                        )
                        return typeCollector
                    }, [])
                }
            </>
        </Modal>
    )
}

export default LegendModal
