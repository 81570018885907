import React from 'react'
import { useTrainingLaneLogo } from 'src/hooks/useTrainingLaneLogo'
import { GatsbyImage } from 'gatsby-plugin-image'

const MaintenanceMessage = ({ maintenanceMessage }) => {
    const trainingLaneLogo = useTrainingLaneLogo()

    return (
        <div className="blocked-wrapper">
            <GatsbyImage image={trainingLaneLogo} loading="lazy" alt="Logo" />
            {maintenanceMessage.split('//').map((message, i) => <p key={i}>{message}</p>)}
        </div>
    )
}

export default MaintenanceMessage