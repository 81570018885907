import React, { useEffect } from 'react'
import anime from 'animejs'
import classNames from 'classnames'
import { themeColors, logoOutlineSvgPathDrawings } from 'training-lane-booking-shared/utilities/constants'
import logoLoadingClasses from './LogoLoading.module.scss'

const LogoLoading = () => {
    useEffect(() => {
        // noinspection JSUnusedGlobalSymbols
        anime({
            targets: '.logo-loading .lines path',
            strokeLinecap: 'round',
            strokeDashoffset: [anime.setDashoffset, 0],
            easing: 'easeInOutSine',
            duration: 1000,
            delay: (el, i) => i * 250,
            direction: 'alternate',
            loop: true
        })
    }, [])
    
    return (
        <div className={classNames("logo-loading", logoLoadingClasses.loading)}>
            <svg width={200} height={200} viewBox="513 330 330 540">
                <g fill="none" fillRule="evenodd" strokeWidth="5" className="lines">
                    {
                        logoOutlineSvgPathDrawings.blue.map(d => <path key={d} d={d} stroke={themeColors.brandBlue} />)
                    }
                    {
                        logoOutlineSvgPathDrawings.red.map(d => <path key={d} d={d} stroke={themeColors.brandRed} />)
                    }
                </g>
            </svg>
        </div>
    )
}

export default LogoLoading