/** @namespace settings.maintenance */

import React, { useCallback, useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { connect, useSelector } from 'react-redux'
import { useFirebase, useFirestore } from 'react-redux-firebase'
import Cookies from 'js-cookie'
import BackgroundSlider from 'gatsby-image-background-slider'
import has from 'lodash/has'
import capitalize from 'lodash/capitalize'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import semverLt from 'semver/functions/lt'
import queryString from 'query-string'
import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import { useFirebaseFunctions } from 'src/hooks/useFirebaseFunctions'
import { useBackgroundImages } from 'src/hooks/useBackgroundImages'
import { useIsLoggedIn } from 'training-lane-booking-shared/hooks/useIsLoggedIn'
import { useInitFirestoreData } from 'training-lane-booking-shared/hooks/useInitFirestoreData'
import { useFirestoreLoading } from 'training-lane-booking-shared/hooks/useFirestoreLoading'
import packageJson from '../../../package.json'
import TopStripe from '../TopStripe/TopStripe'
import LogoLoading from 'src/components/UI/LogoLoading/LogoLoading'
import AlternateRenderMessage from 'src/components/UI/AlternateRenderMessage/AlternateRenderMessage'
import OfflineComponent from 'src/components/UI/OfflineComponent/OfflineComponent'
import Header from 'src/components/Layout/Header/Header'
import Footer from 'src/components/Layout/Footer/Footer'
import Modal from 'src/components/UI/Modal/Modal'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import ConfirmModalShared from 'training-lane-booking-shared/components/Modals/ConfirmModalShared/ConfirmModalShared'
import UsePortraitModal from 'src/components/Modals/UsePortraitModal/UsePortraitModal'
import DownloadMobileAppModal from 'src/components/Modals/DownloadMobileAppModal/DownloadMobileAppModal'
import PricesSpecialModal from 'src/components/Modals/PricesSpecialModal/PricesSpecialModal'
import LoginModal from 'src/components/Modals/LoginModal/LoginModal'
import UnpaidModal from 'src/components/Modals/UnpaidModal/UnpaidModal'
import FaqModal from 'src/components/Modals/FaqModal/FaqModal'
import LegendModal from 'src/components/Modals/LegendModal/LegendModal'
import PurchaseModal from 'src/components/Modals/PurchaseModal/PurchaseModal'
import UnsupportedVersion from 'src/components/UnsupportedVersion/UnsupportedVersion'
import MaintenanceMessage from 'src/components/MaintenanceMessage/MaintenanceMessage'
import {
    getYearMonthDate,
    isSpecialPricePeriod,
    listenerSelectorByCollection
} from 'training-lane-booking-shared/utilities/functions'
import { roles, protectedRoutes } from 'training-lane-booking-shared/utilities/constants'
import textConstants from 'training-lane-booking-shared/utilities/text'
import 'src/assets/scss/main.scss'

const Layout = ({
    location,
    children,
    landingPage = false,
    onGrid = false,
    now,
    loading,
    canPrePay,
    roleId,
    credits,
    areSlotsLoading,
    pricesSpecial,
    bookingMode,
    pageTitle,
    modalStatePaymentProcessor,
    globalLoading,
    utilsGetNowLoading,
    settings,
    updateUser,
    logoutMinimal,
    setModalState,
    setBookingMode,
    purgePayments,
    log
}) => {
    const [newerVersion, setNewerVersion] = useState(null)
    const [maintenanceMessage, setMaintenanceMessage] = useState(null)
    const [showUsePortraitModal, setShowUsePortraitModal] = useState(false)
    const [newPageTitle, setNewPageTitle] = useState(textConstants.PAGE_NOT_FOUND)

    const { title, description, canonicalUrl, menuLinks } = useSiteMetadata()
    const backgroundImagesQuery = useBackgroundImages()
    const firebase = useFirebase()
    const firestore = useFirestore()
    const isLoggedIn = useIsLoggedIn()
    const firestoreLoading = useFirestoreLoading()
    useFirebaseFunctions()

    useInitFirestoreData(firebase, firestore, process.env.FIREBASE_FUNCTIONS_BASEPATH)

    const currentRoute = location.pathname.split('/')[1]

    // noinspection JSUnresolvedVariable
    const isStaffOrAdmin = useSelector(state =>
        [roles.staff.id, roles.admin.id].includes(state.firebase.profile.roleId)
    )

    // noinspection JSUnresolvedVariable
    const isLoading = useSelector(
        state =>
            (state.loading.global === true &&
                state.loading.effects.auth.logoutMinimal === false) ||
            state.firebase.profile.isLoaded === false ||
            state.slotPicker.areSlotsLoading === true
    )

    const firebaseLog = useCallback((message, error) => {
        log({ message, error, firebase })
    }, [firebase, log])

    const settingsListener = useSelector(state =>
        listenerSelectorByCollection(state, 'settings')
    )

    useEffect(() => {
        const recoverEmail = async () => {
            if (firebase) {
                const params = queryString.parse(location.search)
                if (
                    isLoggedIn &&
                    ['verifyEmail', 'resetPassword', 'recoverEmail'].includes(params.mode)
                ) {
                    setModalState('confirm', {
                        state: true,
                        props: {
                            heading: textConstants.ERROR_INVALID_URL,
                            smallPrint: textConstants.ERROR_INVALID_URL_ACTION,
                            single: true
                        }
                    })
                }

                if (
                    isLoggedIn === false &&
                    ['recoverEmail'].includes(params.mode) &&
                    params.oobCode !== undefined
                ) {
                    // noinspection JSCheckFunctionSignatures
                    const checkedCode = await firebase.auth()
                        .checkActionCode(params.oobCode)
                        .then(res => res)
                        .catch(error => {
                            firebaseLog(
                                'Error in Layout / recoverEmail',
                                error
                            )
                        })

                    if (
                        checkedCode &&
                        checkedCode?.operation === 'RECOVER_EMAIL'
                    ) {
                        await updateUser({
                            action: 'recoverEmail',
                            user: { email: checkedCode.data.email },
                            firebase
                        })
                            .catch(error => {
                                firebaseLog(
                                    'Error in Layout / recoverEmail-checkedCode',
                                    error
                                )
                            })
                    }
                }
            }
        }

        recoverEmail().then()
    }, [firebase, firebaseLog, isLoggedIn, location.search, setModalState, updateUser])

    useEffect(() => {
        if (!settings && settingsListener === undefined) {
            const settingsQueryObj = {
                collection: 'settings'
            }
            const getSettings = async () => {
                // noinspection JSUnresolvedFunction
                await firestore.onSnapshot(settingsQueryObj)
            }
            getSettings().catch(error =>
                firebaseLog('Error in Layout/getSettings', error)
            )
        }
    }, [firebaseLog, firestore, settings, settingsListener])

    useEffect(() => {
        if (settings === undefined) {
            setNewerVersion(undefined)
            setMaintenanceMessage(undefined)
            return
        }

        const minVersion = Object.values(settings).find(set => has(set, 'minVersion'))?.minVersion
        setNewerVersion(semverLt(packageJson.version, minVersion) ? minVersion : undefined)

        setMaintenanceMessage(Object.values(settings).find(set => has(set, 'maintenance'))?.maintenance)
    }, [settings])

    useEffect(() => {
        if (protectedRoutes.includes(pageTitle) || !pageTitle) {
            setNewPageTitle(pageTitle)
        }
    }, [pageTitle])

    useEffect(() => {
        if (roles) {
            if (currentRoute === 'booking') {
                if (
                    bookingMode === 'normal' &&
                    [roles.staff.id, roles.admin.id].includes(roleId)
                ) {
                    setBookingMode('admin')
                }
                if (
                    bookingMode === 'admin' &&
                    ![roles.staff.id, roles.admin.id].includes(roleId)
                ) {
                    setBookingMode('normal')
                }
            } else if (currentRoute === 'me' && bookingMode === 'admin') {
                setBookingMode('normal')
            } else if (
                currentRoute === 'admin' &&
                bookingMode === 'normal' &&
                [roles.staff.id, roles.admin.id].includes(roleId)
            ) {
                setBookingMode('admin')
            }
        }
    }, [bookingMode, currentRoute, location, roleId, setBookingMode])

    useEffect(() => {
        if (!isLoading) {
            const logoutCondition =
                !isLoggedIn && newPageTitle !== textConstants.PAGE_NOT_FOUND
            if (logoutCondition) {
                logoutMinimal()
            }
            setModalState('login', { state: location.pathname === '/faq' ? false : logoutCondition })
        }
    }, [isLoading, logoutMinimal, setModalState, newPageTitle, isLoggedIn, location.pathname])

    useEffect(() => {
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    })

    useEffect(() => {
        setModalState('pricesSpecial', {
            state:
                isSpecialPricePeriod(roleId, getYearMonthDate(now), pricesSpecial) &&
                (Cookies.get('pricesSpecialDismissed') === undefined ||
                    parseInt(Cookies.get('pricesSpecialDismissed'), 10) !== 1)
        })
    }, [setModalState, roleId, now, pricesSpecial])

    useEffect(() => {
        setModalState('downloadMobileApp', {
            state:
                Cookies.get('downloadMobileDismissed') === undefined ||
                parseInt(Cookies.get('downloadMobileDismissed'), 10) !== 1
        })
    }, [setModalState])

    useEffect(() => {
        if (
            modalStatePaymentProcessor.state === false &&
            modalStatePaymentProcessor.props.triggerPostClose === true
        ) {
            purgePayments()
            if (currentRoute !== 'booking') {
                const goToBooking = async () => await navigate('/booking')
                goToBooking().then()
            }
            setModalState('paymentProcessor', {
                ...modalStatePaymentProcessor,
                props: {}
            })
        }
    }, [currentRoute, modalStatePaymentProcessor, purgePayments, setModalState])

    useEffect(() => {
        if (location.pathname === '/faq') {
            setModalState('faq', { state: true })
        }
    }, [location, setModalState])

    const handleResize = () => {
        setShowUsePortraitModal(
            window.matchMedia('(orientation: landscape)').matches &&
            window.innerHeight < 480
        )
    }
    const getNewChildren = () => {
        if (isLoggedIn && !isStaffOrAdmin && newPageTitle === 'admin') {
            return (
                <AlternateRenderMessage>
                    {textConstants.UNAUTHORIZED}
                </AlternateRenderMessage>
            )
        }
        if (!isLoggedIn && newPageTitle !== textConstants.PAGE_NOT_FOUND) {
            return (
                <AlternateRenderMessage>
                    {textConstants.LOGGED_OUT}
                </AlternateRenderMessage>
            )
        }
        return children
    }

    const confirmModalProps = {
        Modal,
        Confirm,
        buttonStyles: {
            dismiss: confirmClasses.confirmButtonProceedSafe,
            abort: confirmClasses.confirmButtonAbort,
            proceed: confirmClasses.confirmButtonProceedDanger
        }
    }

    if (maintenanceMessage === null && newerVersion === null) {
        return <LogoLoading />
    } else if (newerVersion !== undefined) {
        return (
            <>
                <UnsupportedVersion versionInfo={{
                    minVersion: newerVersion,
                    currentVersion: packageJson.version
                }} />
            </>
        )
    } else if (maintenanceMessage !== undefined) {
        return (
            <>
                <MaintenanceMessage maintenanceMessage={maintenanceMessage} />
            </>
        )
    }

    const headerProps = {
        credits,
        landingPage,
        pageTitle: newPageTitle
    }

    const topStripeProps = {
        location,
        menuLinks,
        landingPage
    }

    return (
        <>
            {(areSlotsLoading || (globalLoading && !utilsGetNowLoading) || firestoreLoading || loading) && (
                <LogoLoading />
            )}
            <OfflineComponent />
            <LoginModal location={location} />
            <UsePortraitModal showUsePortraitModal={showUsePortraitModal} />
            <DownloadMobileAppModal />
            <PricesSpecialModal />
            <FaqModal />
            {canPrePay && <PurchaseModal />}
            {isStaffOrAdmin && <LegendModal />}
            <UnpaidModal />
            <ConfirmModalShared {...confirmModalProps} />
            <Helmet
                title={pageTitle ? `${title} // ${capitalize(pageTitle)}` : title}
                meta={[{ name: 'description', content: description }]}
                link={[{ rel: 'canonical', href: canonicalUrl }]} />
            <TopStripe {...topStripeProps} />
            {!landingPage && <Header {...headerProps} />}
            <div className="body">
                <section className={classNames(
                    'main',
                    {
                        'main-not-home': !landingPage,
                        'main-on-grid': onGrid
                    }
                )}>
                    {getNewChildren()}
                    {
                        !landingPage &&
                        <BackgroundSlider
                            query={backgroundImagesQuery}
                            initDelay={7}
                            transition={4}
                            duration={9}
                        />
                    }
                </section>
            </div>
            <Footer />
        </>
    )
}

const mapState = state => ({
    now: state.utils.now,
    loading: state.utils.loading,
    canPrePay: state.auth.capabilities.canPrePay,
    roleId: state.firebase.profile.roleId,
    credits: state.firebase.profile.credits,
    areSlotsLoading: state.slotPicker.areSlotsLoading,
    pricesSpecial: state.firestore.ordered?.pricesSpecial,
    bookingMode: state.slotPicker.bookingMode,
    pageTitle: state.utils.pageTitle,
    modalStatePaymentProcessor: state.utils.modalState.paymentProcessor,
    globalLoading: state.loading.global,
    utilsGetNowLoading: state.loading.effects.utils.getNow,
    settings: state.firestore?.data?.settings
})

const mapDispatch = dispatch => ({
    updateUser: payload => dispatch.user.updateUser(payload),
    logoutMinimal: () => dispatch.auth.logoutMinimal(),
    setModalState: (modalName, payload) =>
        dispatch.utils.setModalState(modalName, payload),
    setBookingMode: mode => dispatch.slotPicker.setBookingMode(mode),
    purgePayments: () => dispatch.payments.purgePayments(),
    log: payload => dispatch.utils.log(payload)
})

export default connect(mapState, mapDispatch)(Layout)
