import { init } from '@rematch/core'
import persistPlugin from '@rematch/persist'
import loadingPlugin from '@rematch/loading'
import createWebStorage from 'redux-persist/lib/storage/createWebStorage'
import * as models from 'training-lane-booking-shared/rematch/models'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { firebaseReducer } from 'react-redux-firebase'
import { firestoreReducer } from 'redux-firestore'

const config = {
    apiKey: process.env.FIREBASE_APIKEY,
    authDomain: process.env.FIREBASE_AUTHDOMAIN,
    databaseURL: process.env.FIREBASE_DATABASEURL,
    projectId: process.env.FIREBASE_PROJECTID,
    storageBucket: process.env.FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGINGSENDERID,
    appId: process.env.FIREBASE_APPID,
    measurementId: process.env.FIREBASE_MEASUREMENTID
}

if (typeof window !== 'undefined') {
    firebase.initializeApp(config)

    if (process.env.NODE_ENV === 'development') {
        firebase.auth().useEmulator('http://localhost:9099')
        firebase.firestore().settings({
            host: 'localhost:8080',
            ssl: false
        })
    }
}

const createNoopStorage = () => {
    // noinspection JSUnusedGlobalSymbols
    return {
        getItem() {
            // eslint-disable-next-line no-undef
            return Promise.resolve(null)
        },
        setItem(_key, value) {
            // eslint-disable-next-line no-undef
            return Promise.resolve(value)
        },
        removeItem() {
            // eslint-disable-next-line no-undef
            return Promise.resolve()
        }
    }
}

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage()

const persistConfig = {
    key: 'root',
    whitelist: ['user', 'auth', 'algoliaPersisted'],
    version: 1,
    storage
}

const loadingConfig = {}

// noinspection JSCheckFunctionSignatures
const store = init({
    models,
    redux: {
        reducers: {
            firebase: firebaseReducer,
            firestore: firestoreReducer
        },
        devtoolOptions: {
            disabled: process.env.GATSBY_ACTIVE_ENV === 'production' || process.env.GATSBY_ACTIVE_ENV === 'demo',
            maxAge: 500
        }
    },
    plugins: [
        persistPlugin(persistConfig),
        loadingPlugin(loadingConfig)
    ]
})

// noinspection JSUnusedGlobalSymbols
export const { getState } = store

export default store
