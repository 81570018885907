import { graphql, useStaticQuery } from 'gatsby'

export const useTrainingLaneLogo = () => {
    // noinspection GraphQLUnresolvedReference
    const images = useStaticQuery(
        graphql`
            query HeaderImagesLargest {
                file(relativePath: { eq: "TrainingLane_logo.png" }) {
                    childImageSharp {
                        gatsbyImageData(height: 220, layout: FIXED)
                    }
                }
            }
        `
    )
    // noinspection JSUnresolvedVariable
    return images.file.childImageSharp.gatsbyImageData
}
