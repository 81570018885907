import React from 'react'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import Modal from 'src/components/UI/Modal/Modal'
import PricesSpecial from 'src/components/Purchase/PricesSpecial/PricesSpecial'
import {
    getYearMonthDate,
    isSpecialPricePeriod
} from 'training-lane-booking-shared/utilities/functions'

const PricesSpecialModal = ({ now, roleId, pricesSpecial }) => {
    const foundSpecialPrice = isSpecialPricePeriod(
        roleId,
        getYearMonthDate(now),
        pricesSpecial
    )

    return (
        <Modal
            name="pricesSpecial"
            handleClose={() => {
                Cookies.set('pricesSpecialDismissed', 1, { expires: 30 })
            }}
            nested>
            {foundSpecialPrice && <PricesSpecial {...foundSpecialPrice} />}
        </Modal>
    )
}

const mapState = state => ({
    now: state.utils.now,
    roleId: state.firebase.profile.roleId,
    pricesSpecial: state.firestore.ordered.pricesSpecial
})

export default connect(mapState)(PricesSpecialModal)
