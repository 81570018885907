import { useState, useEffect } from 'react'

export const useIsSmallerThan = (size, dimensionType = 'width') => {
    const [isSmall, setIsSmall] = useState(false)

    useEffect(() => {
        let value = window !== undefined ? window.innerWidth <= size : false
        
        if (dimensionType === 'height') {
            value = window !== undefined ? window.innerHeight <= size : false
        }
        
        const handleResize = () => {
            setIsSmall(value)
        }
        
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [dimensionType, size])

    return isSmall
}