import React, { useState } from 'react'
import has from 'lodash/has'
import Form from 'src/components/UI/Form/Form'
import Confirm from 'src/components/UI/Confirm/Confirm'
import confirmClasses from 'src/components/UI/Confirm/Confirm.module.scss'
import textConstants from 'training-lane-booking-shared/utilities/text'

const ReAuthForm = ({ button, handleSendEmailVerification, handleUpdatePhoneNumber, handleReAuth }) => {
    const [user, setUser] = useState({})
    const [error, setError] = useState({ global: false })
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState('')
    const [transitionIn, setTransitionIn] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const swapTransition = () => {
        setTransitionIn(true)
        if (error.global === true) {
            setTimeout(() => {
                setTransitionIn(false)
            }, 500)
        }
    }
    const handleUpdate = e => {
        if (error.global) {
            swapTransition()
        }
        setError({ global: false })
        setSuccess(false)
        setMessage('')
        setUser({ ...user, [e.target.name]: e.target.value })
    }
    const handleEmailVerificationSubmit = (authData, resend) => {
        setDisabled(true)
        handleSendEmailVerification(authData, resend)
    }
    const handlePhoneNumberSubmit = authData => {
        setDisabled(true)
        swapTransition()
        const emptyPhone = !has(user, 'phone') || (has(user, 'phone') && user.phone.length === 0)
        if (emptyPhone) {
            let errorObj = { global: true }
            if (emptyPhone) {
                errorObj.phone = true
            }
            setError(errorObj)
            setMessage(textConstants.FORM_EMPTY_ALL)
            setDisabled(false)
            return
        }
        handleUpdatePhoneNumber(authData, user.phone)
            .then(() => {
                setError({ global: false })
                setMessage(textConstants.FORM_UPDATE_SUCCESS)
                setSuccess(true)
                setDisabled(false)
            })
    }
    let formProps = { disabled }
    if (button.fn === 'handlePhoneNumberSubmit') {
        Object.assign(formProps, {
            fieldList: [
                {
                    label: textConstants.PHONE,
                    type: 'tel',
                    name: 'phone',
                    autoComplete: 'home tel',
                    errorCond: error.phone,
                    value: user.phone
                }
            ],
            error, success, message, handleUpdate, transitionIn
        })
    }
    const fnList = { handleEmailVerificationSubmit, handlePhoneNumberSubmit, handleReAuth }
    return (
        <>
            <br />
            <Form
                confirm={
                    <Confirm
                        buttons={
                            [
                                {
                                    buttonClasses: confirmClasses.confirmButtonProceedSafe,
                                    handleFn: () => fnList[button.fn](...button.data),
                                    label: button.label,
                                    disabled
                                }
                            ]
                        } />
                }
                { ...formProps }
            />
        </>
    )
}

export default ReAuthForm
