import React from 'react'
import packageJson from '../../../../package.json'
import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import footerClasses from './Footer.module.scss'

const Footer = () => {
    const { title } = useSiteMetadata()
    
    return (
        <section className={footerClasses.footer}>
            <ul className={footerClasses.copyright}>
                <li>&copy; {title}</li>
                <li>&#47;&#47;</li>
                <li><a href="https://benomatis.com" target="_blank" rel="noreferrer">benomatis.com</a></li>
                <li>&#47;&#47;</li>
                <li>v{packageJson.version}</li>
            </ul>
        </section>
    )
}

export default Footer
