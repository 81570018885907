/** @namespace firebase.handleRedirectResult */
import React, { useEffect, useState } from 'react'
import { navigate } from 'gatsby'
import { connect } from 'react-redux'
import queryString from 'query-string'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth'
import { useFirebase } from 'react-redux-firebase'
import { useFirebaseFunctions } from 'src/hooks/useFirebaseFunctions'
import ReAuthForm from '../ReAuthForm/ReAuthForm'
import { roles } from 'training-lane-booking-shared/utilities/constants'
import textConstants from 'training-lane-booking-shared/utilities/text'
import './authui-styling.global.scss'

const initButtonData = {
    show: false,
    fn: '',
    data: {},
    label: ''
}

const AuthUi = ({
    location,
    localProfile,
    pageTitle,
    loginSuccess,
    updateUser,
    sendEmailVerification,
    log
}) => {
    const firebase = useFirebase()
    const firebaseLog = (message, error) => {
        log({ message, error, firebase })
    }
    useFirebaseFunctions()
    const [smallPrint, setSmallPrint] = useState(textConstants.LOGIN_INTRO)
    const [button, setButton] = useState(initButtonData)

    useEffect(() => {
        if (firebase) {
            const params = queryString.parse(location.search)
            if (['verifyEmail', 'resetPassword', 'recoverEmail'].includes(params.mode)) {
                setSmallPrint('')
            }
        }
    }, [location, firebase])

    const handleSendEmailVerification = (authData, resend = false) => {
        sendEmailVerification(authData.user, process.env.SITE_URL)
            .then(() => {
                if (resend) {
                    setButton({
                        show: true,
                        fn: 'dummy',
                        data: {},
                        label: textConstants.SENT
                    })
                }
            })
            .catch(error => firebaseLog('Error in sendEmailVerification', error))
    }

    const handleUpdatePhoneNumber = async (authData, phone) => {
        setSmallPrint(textConstants.LOADING)
        return await loginSuccessInternal(authData).then(async () => {
            // noinspection JSUnresolvedVariable
            const user = {
                id: authData.user.uid,
                name: authData.user.displayName,
                email: authData.user.email,
                roleId: localProfile.roleId,
                phone
            }
            
            const roleIds = Object.keys(roles).map(roleName => roles[roleName].id)
            
            if (!roleIds.includes(localProfile.roleId)) {
                firebaseLog('Caught you you naughty b*tch!! This is how the localProfile object looks like: ', localProfile)
            }
            
            await updateUser({ action: 'update', user, actionCodeSettings: {}, firebase })
                .then(() => {
                    if (authData.user.emailVerified === false) {
                        resendEmailVerification(authData)
                    }
                })
                .catch(error => firebaseLog('Error in AuthUI / updateUser (phone)', error))
        })
    }

    const resendEmailVerification = async authData => {
        await firebase.logout()
        setSmallPrint(`${textConstants.LOGIN_ALMOST_THERE} ${textConstants.LOGIN_CLICK_LINK_IN_EMAIL} ${textConstants.LOGIN_CLICK_RESEND}`)
        setButton({
            show: true,
            fn: 'handleEmailVerificationSubmit',
            data: [authData, true],
            label: textConstants.RESEND
        })
    }

    const handleReAuth = async () => {
        window.location.href = '/'
    }

    const loginSuccessInternal = async authData => {
        // noinspection JSUnresolvedFunction,JSValidateTypes
        firebase.handleRedirectResult(authData).then(async profile => {
            loginSuccess(profile)
            if (![roles.staff.id, roles.admin.id].includes(profile.roleId) && pageTitle === 'admin') {
                await navigate('/')
            }
            return false
        })
    }

    // noinspection JSUnusedGlobalSymbols
    const firebaseAuthProps = firebase => ({
        uiConfig: {
            signInOptions: [
                {
                    provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                    requireDisplayName: true
                }
            ],
            callbacks: {
                signInSuccessWithAuthResult: (authData) => {
                    setSmallPrint(textConstants.LOADING)
                    // noinspection JSValidateTypes
                    firebase.handleRedirectResult(authData).then(async () => {
                        const phoneExists = await authData.user.getIdTokenResult().then(token => token.claims.phoneExists)
                        if (authData.additionalUserInfo.isNewUser) {
                            handleSendEmailVerification(authData)
                        }
                        if (phoneExists === false || phoneExists === undefined) {
                            setSmallPrint(`${textConstants.LOGIN_LAST_STEP} ${textConstants.LOGIN_PROVIDE_PHONE}`)
                            setButton({
                                show: true,
                                fn: 'handlePhoneNumberSubmit',
                                data: [authData],
                                label: textConstants.PROCEED
                            })
                        } else if (authData.user.emailVerified === false) {
                            await resendEmailVerification(authData)
                        } else {
                            await loginSuccessInternal(authData)
                        }

                        return false
                    })
                },
                signInFailure: function(error) {
                    firebaseLog('Error in AuthUi / signInFailure', error)
                }
            }
        },
        firebaseAuth: firebase.auth()
    })

    const reAuthFormProps = {
        button,
        handleSendEmailVerification,
        handleUpdatePhoneNumber,
        handleReAuth
    }

    return (
        <>
            <h1>{textConstants.LOGIN}</h1>
            {smallPrint && <div className="firebaseui-custom-small-print">{smallPrint}</div>}
            {firebase !== null ? <StyledFirebaseAuth {...firebaseAuthProps(firebase)} /> : null}
            {button.show && <ReAuthForm {...reAuthFormProps} />}
        </>
    )
}

const mapState = state => ({
    localProfile: state.firebase.profile,
    pageTitle: state.utils.pageTitle
})

const mapDispatch = dispatch => ({
    loginSuccess: profile => dispatch.auth.loginSuccess(profile),
    updateUser: payload => dispatch.user.updateUser(payload),
    sendEmailVerification: (currentUser, url) => dispatch.user.sendEmailVerification({ currentUser, url }),
    log: payload => dispatch.utils.log(payload)
})

export default connect(mapState, mapDispatch)(AuthUi)
