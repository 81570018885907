import React from 'react'
import { useModalResize } from 'src/hooks/useModalResize'
import purchaseModalClasses from 'src/components/Modals/PurchaseModal/PurchaseModal.module.scss'

const PriceListWrapper = ({ children }) => {
    const height = useModalResize(130, 140)
    
    return (
        <div className={purchaseModalClasses.purchaseModal}>
            <div className={purchaseModalClasses.scrollable} style={{ height }}>
                {children}
            </div>
        </div>
    )
}

export default PriceListWrapper