import React from 'react'
import classNames from 'classnames'
import { useSiteMetadata } from 'src/hooks/useSiteMetadata'
import Modal from 'src/components/UI/Modal/Modal'
import MainNav from 'src/containers/TopStripe/MainNav/MainNav'
import menuModalClasses from './MenuModal.module.scss'


const MenuModal = ({ location }) => {
    const { menuLinks } = useSiteMetadata()
    return (
        <Modal
            name='menu'
            className="menu-popup"
            showDoneButton={false} >
            <div className={classNames(
                menuModalClasses.menuModalWrapper,
                {
                    [menuModalClasses.menuModalWrapperLandingPage]: location.pathname.split('/')[1].length === 0
                }
            )}>
                <MainNav menuLinks={menuLinks} location={location} />
            </div>
        </Modal>
    )
}

export default MenuModal