import React, { Fragment, useEffect, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import formClasses from './Form.module.scss'
import textConstants from 'training-lane-booking-shared/utilities/text'

// TODO: for later: consider replacing this with react-hook-form (lightweight form library) and joi.dev (robust validation)
const Form = ({
    heading = null,
    description = null,
    error = {},
    success = false,
    message = null,
    handleUpdate = () => {},
    fieldList = [],
    confirm = null,
    transitionIn = true,
    disabled = false
}) => {
    const fieldRef = useRef(null)
    
    useEffect(() => {
        fieldRef.current !== null && fieldRef.current.focus()
    }, [])
    
    const handleSubmit = e => {
        e.preventDefault()
    }
    
    const labelComments = (verified, optional) => {
        let comment = null
        if (optional) {
            comment = {
                class: formClasses.fieldOptional,
                text: textConstants.FORM_FIELD_OPTIONAL
            }
        }
        if (verified !== undefined && !verified) {
            comment = {
                class: formClasses.fieldOptional,
                text: textConstants.FORM_EMAIL_UNVERIFIED
            }
        }
        return comment && <span
            className={comment.class}>({comment.text})</span>
    }
    
    return (
        <>
            {heading &&
                <>
                    <h1>{heading}</h1>
                    <br />
                </>
            }
            {description &&
                <>
                    <div>{description}</div>
                    <br />
                </>
            }
            <CSSTransition
                in={transitionIn}
                timeout={200}
                classNames={{ ...formClasses }}>
                <div className={error.global ? 'error' : success ? 'success' : ''}>
                    {message}
                </div>
            </CSSTransition>
            <br />
            <form
                method="post"
                onSubmit={handleSubmit}>
                {
                    fieldList.map(({
                        autoComplete,
                        autoFocus,
                        errorCond,
                        label,
                        name,
                        type,
                        value,
                        verified,
                        checked,
                        optional,
                        disabled: inputDisabled,
                        min,
                        max,
                        step,
                        labelStyle,
                        inputStyle,
                        insertComponent
                    }, i) => {
                        if (insertComponent) {
                            return (
                                <Fragment key={i}>
                                    {insertComponent}
                                </Fragment>
                            )
                        }
                        
                        let inputProps = {
                            type,
                            name,
                            autoComplete,
                            className: errorCond ? 'error' : '',
                            onChange: handleUpdate,
                            value: value ?? '',
                            checked,
                            disabled: disabled || inputDisabled,
                            style: inputStyle
                        }
                        if (autoFocus) {
                            Object.assign(inputProps, { ref: fieldRef })
                        }
                        if (min !== undefined) {
                            Object.assign(inputProps, { min })
                        }
                        if (max !== undefined) {
                            Object.assign(inputProps, { max })
                        }
                        if (step !== undefined) {
                            Object.assign(inputProps, { step })
                        }
                        
                        let labelProps

                        if (['checkbox', 'number'].includes(type)) {
                            labelProps = {
                                style: {
                                    display: 'inline',
                                    marginRight: 10
                                }
                            }
                        } else {
                            labelProps = {
                                style: {
                                    marginTop: 20
                                }
                            }
                        }
                        
                        return (
                            <Fragment key={i}>
                                <label { ...labelProps }>
                                    <span style={labelStyle}>{label}</span> {labelComments(verified, optional)}
                                    <input { ...inputProps } />
                                </label>
                            </Fragment>
                        )
                    })
                }
                {confirm}
            </form>
        </>
    )
}

export default Form
