import React from 'react'
import classNames from 'classnames'
import confirmClasses from './Confirm.module.scss'

const Confirm = ({
    heading = null,
    info = null,
    smallPrint = null,
    buttons = [],
    centerButton = true,
    inline = true,
    defaultDimensions = true,
    forceStacked = false,
    reportListView = false,
    newLineAboveButtons = true
}) => {
    if (buttons.length === 0) {
        return null
    }
    const buttonWrapperClasses = [
        confirmClasses.confirmButtonWrapper,
        {
            [confirmClasses.confirmButtonWrapperHeight]: defaultDimensions,
            [confirmClasses.confirmButtonWrapperHeightReport]: reportListView,
            'grid-wrapper-100': !defaultDimensions,
            'align-center': centerButton,
            'grid-wrapper': buttons.length > 1
        }
    ]
    const buttonBaseClasses = [confirmClasses.confirmButton]
    return (
        <>
            {
                (heading || smallPrint) &&
                <>
                    <div className="align-center">
                        {heading && <div className="spacer-small">{heading}</div>}
                        {info && <div className="spacer-small">{info}</div>}
                        {smallPrint && <div className="spacer-small small-print">{smallPrint}</div>}
                    </div>
                    {newLineAboveButtons && <br />}
                </>
            }
            <div className={classNames(buttonWrapperClasses)}>
                {
                    buttons.map((item, i) => {
                        const disabled = item.disabled ?? false
                        const button = (
                            <button
                                key={i}
                                type={item.takeEnter || buttons.length === 1 ? 'submit' : 'button'}
                                className={classNames(
                                    buttonBaseClasses,
                                    item.buttonClasses,
                                    {
                                        'disabled': disabled
                                    }
                                )}
                                style={item.buttonStyle}
                                disabled={disabled}
                                onClick={item.handleFn}>
                                {item.label}
                            </button>
                        )
                        return buttons.length > 1 || forceStacked ?
                            <div key={i} className={classNames(
                                `col-${12 / (inline ? buttons.length : 1)}`,
                                {
                                    [confirmClasses.stacked]: !inline
                                }
                            )}>{button}</div> : button
                    })
                }
            </div>
        </>
    )
}

export default Confirm
