import React from 'react'
import classNames from 'classnames'
import Counter from 'src/components/Counter/Counter'
import { zeroCredits } from 'training-lane-booking-shared/utilities/constants'
import counterContainerClasses from './CounterContainer.module.scss'

const CounterContainer = ({
    typesArray,
    laneTypesLength,
    onList = false,
    adminView = false,
    credits,
    firebaseProfileIsLoaded,
    handleCounterClick,
    shouldCounterAnimate,
    makeCounterAnimate,
    disabled = false,
    landingPage = false
}) => typesArray.reduce((counterCollector, type, i) => {
    if ((i === 0 || i === laneTypesLength) && !onList) {
        const titleClasses = classNames(
            'col-1',
            counterContainerClasses.counterContainerTitle,
            {
                [counterContainerClasses.counterContainerTitleLanes]: i === 0,
                [counterContainerClasses.counterContainerTitleIr]: i === laneTypesLength
            }
        )
        counterCollector.push(<div key={`${type.id}-title`}
                                   className={titleClasses}>{type.description.categoryShort}</div>)
    }
    counterCollector.push(
        <Counter
            adminView={adminView}
            creditsLoading={!firebaseProfileIsLoaded}
            credits={credits ?? zeroCredits}
            key={type.id}
            type={type}
            handleCounterClick={handleCounterClick}
            shouldCounterAnimate={shouldCounterAnimate && makeCounterAnimate(type)}
            typesLength={typesArray.length}
            disabled={disabled}
            landingPage={landingPage}
        />
    )
    return counterCollector
}, [])

export default CounterContainer
