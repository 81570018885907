import React from 'react'
import AuthUi from 'src/components/Modals/LoginModal/AuthUi/AuthUi'
import Modal from 'src/components/UI/Modal/Modal'

const LoginModal = ({ location }) => (
    <Modal name='login'>
        <AuthUi location={location} />
    </Modal>
)

export default LoginModal
