import { useEffect, useState } from 'react'

export const useModalResize = (
    big = 102,
    small = 104,
    condition = window.innerWidth >= 480
) => {
    const [height, setHeight] = useState(0)
    
    useEffect(() => {
        const handleResize = () => {
            // TODO: for later: there should be a smarter way to calculate this height
            const minusHeight = condition ? big : small
            setHeight(window.innerHeight - minusHeight)
        }
        
        handleResize()
        window.addEventListener('resize', handleResize)
        
        return () => window.removeEventListener('resize', handleResize)
    }, [big, small, condition])
    return height
}