import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'
import { getGrandTotal, getItemPrice } from 'training-lane-booking-shared/utilities/functions'
import Confirm from '../UI/Confirm/Confirm'
import confirmClasses from '../UI/Confirm/Confirm.module.scss'

const CreditCounter = ({
    user,
    credits,
    type,
    prices,
    groupSessionsUnpaidTotal,
    tempCredits,
    tempTotal,
    setTempCredits,
    setTempTotal,
    logPayment,
    simpleTally = false,
    handleUpdateCounter = () => {}
}) => {
    const firebase = useFirebase()
    
    const tallyIncludeGroupSessions = useSelector(state => state.slotPicker.tallyIncludeGroupSessions)
    
    useEffect(() => {
        handleChange()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    const handleChange = (val = undefined) => {
        setTempCredits({ type: type.id, value: val ? (tempCredits[type.id] + val) : credits[type.id] })
        setTempTotal(
            (
                simpleTally
                    ? 0
                    : parseFloat(
                        getGrandTotal(
                            { ...tempCredits, [type.id]: val ? (tempCredits[type.id] + val) : credits[type.id] },
                            prices
                        )
                    )
            ) + (
                tallyIncludeGroupSessions
                    ? parseFloat(getItemPrice(groupSessionsUnpaidTotal))
                    : 0
            )
        )
    }
    
    const handleGo = () => {
        const creditDiff = tempCredits[type.id] - credits[type.id]
        
        if (creditDiff !== 0) {
            const { promoCredits } = user
            logPayment({
                owner: user.id,
                oldCredits: { credits, promoCredits },
                newCredits: { [type.id]: creditDiff },
                total: tempTotal,
                paymentType: 'vip-promo',
                firebase
            }).then(async res => {
                await handleUpdateCounter(res.updatedCredits, user.groupSessionsUnpaid)
            })
        }
    }
    
    return (
        <Confirm
            buttons={[
                {
                    buttonClasses: [
                        confirmClasses.confirmButtonProceedSafe,
                        confirmClasses.smallest
                    ],
                    handleFn: () => handleChange(-1),
                    label: '-'
                },
                {
                    buttonClasses: [
                        confirmClasses.confirmButtonProceedDanger,
                        confirmClasses.smallest
                    ],
                    handleFn: () => handleChange(1),
                    label: '+'
                },
                {
                    buttonClasses: [
                        confirmClasses.confirmButtonProceedNeutral,
                        confirmClasses.smallest
                    ],
                    handleFn: () => handleChange(),
                    label: 'r'
                },
                {
                    buttonClasses: [
                        confirmClasses.confirmButtonProceedSafe,
                        confirmClasses.smallest
                    ],
                    handleFn: handleGo,
                    label: 'go'
                }
            ]}
        />
    )
}

export default CreditCounter